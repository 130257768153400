
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function labelVehicleWidgetRT () {
    return this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget__label cmTemplate_locked',
        'key': '0'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, 'Showing parts for your'), _createElement('div', { 'className': 'cm_vehicle-widget_vehicle' }, '\n      ', this.vehicleValues?.map(v => v.value).join(' / '), '\n      ', _createElement('div', {
        'className': 'cm_vehicle-widget_button',
        'onClick': this.discardVehicle
    }, [_createElement('svg', {
            'width': '10',
            'height': '10',
            'viewBox': '0 0 10 10',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '4170'
        }, _createElement('path', {
            'd': 'M0.502101 8.67349L4.0042 5.17139L0.502101 1.66929L1.66947 0.501918L5.17157 4.00402L8.67367 0.501918L9.84104 1.66929L6.33894 5.17139L9.84104 8.67349L8.67367 9.84086L5.17157 6.33875L1.66947 9.84085L0.502101 8.67349Z',
            'fill': '#FD4A3F'
        }))])))) : null;
}
        export const componentNames = []