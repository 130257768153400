
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, entryIndex) {
                    return _createElement('div', {
                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, _createElement('div', {
                        'className': 'cm_dropdown_value',
                        'role': 'option',
                        'aria-selected': entry.selected
                    }, entry.value));
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'aria-label': this.useNativeDropdown ? '' : `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`,
                    'tabIndex': this.useNativeDropdown ? '-1' : '0'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.useNativeDropdown ? this.title : '',
                    'tabIndex': this.useNativeDropdown ? '0' : '-1'
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '12281'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '12284'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null), [_createElement('svg', {
                        'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                        'width': '11',
                        'height': '7',
                        'viewBox': '0 0 11 7',
                        'fill': 'none',
                        'xmlns': 'http://www.w3.org/2000/svg',
                        'aria-hidden': 'true',
                        'key': '30240'
                    }, _createElement('path', {
                        'fillRule': 'evenodd',
                        'clipRule': 'evenodd',
                        'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                        'fill': '#1A1717'
                    }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'role': 'listbox',
                        'key': '3516'
                    },
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home cm_vehicle-widget__garage-header' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '91'
    }, _createElement('div', {
        'className': 'cm_vehicle-widget_title',
        'aria-label': 'Shop by vehicle form'
    }, 'SHOP BY VEHICLE'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ])), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': '0'
    }, '\n      ADD\n    ')) : null);
}
        export const componentNames = []