import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/sbfilters-com/Templates/SearchPage.rt'
import { FacetPanel } from 'Components'
import TemplateQueryLabel from 'Stores/sbfilters-com/Templates/QueryLabel.rt'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonMobile from 'Stores/_default-store/Templates/searchBox/dialogButtonMobile.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/sbfilters-com/Templates/fitmentSearch/garage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchlabelVehicleWidget from 'Stores/sbfilters-com/Templates/fitmentSearch/labelVehicleWidget.rt'
import TemplatefitmentSearchgarageVehicleWidget from 'Stores/sbfilters-com/Templates/fitmentSearch/garageVehicleWidget.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { DialogButton } from 'Components'
import TemplatefitmentSearchvehicleWidgetDialogButton from 'Stores/sbfilters-com/Templates/fitmentSearch/vehicleWidgetDialogButton.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import TemplateCustomFacetDialog from 'Stores/sbfilters-com/Templates/CustomFacetDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchResult,
SearchHeader,
SearchBox,
SearchBoxDialogButton,
Garage,
VehicleWidget,
FitmentTable,
SearchBoxDialog,
DialogButton,
ContextDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'QueryLabel': TemplateQueryLabel,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonMobile': TemplatesearchBoxdialogButtonMobile,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/labelVehicleWidget': TemplatefitmentSearchlabelVehicleWidget,
'fitmentSearch/garageVehicleWidget': TemplatefitmentSearchgarageVehicleWidget,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/vehicleWidgetDialogButton': TemplatefitmentSearchvehicleWidgetDialogButton,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'CustomFacetDialog': TemplateCustomFacetDialog
};