
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, !this.isDialogOpened ? _createElement('svg', {
                'className': 'icon--search',
                'height': '15px',
                'version': '1.1',
                'xmlns': 'http://www.w3.org/2000/svg',
                'xmlnsXlink': 'http://www.w3.org/1999/xlink',
                'x': '0px',
                'y': '0px',
                'viewBox': '0 0 15 16',
                'xmlSpace': 'preserve',
                'key': '55'
            }, _createElement('g', {
                'className': 'hover-fill',
                'fill': '#FD4A3F'
            }, _createElement('path', { 'd': 'M13.8333 15.1799L8.58333 9.92993C8.16667 10.2633 7.6875 10.5272 7.14583 10.7216C6.60417 10.916 6.02778 11.0133 5.41667 11.0133C3.90278 11.0133 2.62167 10.4891 1.57333 9.44076C0.524444 8.39188 0 7.11049 0 5.5966C0 4.08271 0.524444 2.80132 1.57333 1.75243C2.62167 0.704098 3.90278 0.179932 5.41667 0.179932C6.93056 0.179932 8.21194 0.704098 9.26083 1.75243C10.3092 2.80132 10.8333 4.08271 10.8333 5.5966C10.8333 6.20771 10.7361 6.7841 10.5417 7.32576C10.3472 7.86743 10.0833 8.3466 9.75 8.76326L15 14.0133L13.8333 15.1799ZM5.41667 9.3466C6.45833 9.3466 7.34389 8.98215 8.07333 8.25326C8.80222 7.52382 9.16667 6.63826 9.16667 5.5966C9.16667 4.55493 8.80222 3.66938 8.07333 2.93993C7.34389 2.21104 6.45833 1.8466 5.41667 1.8466C4.375 1.8466 3.48944 2.21104 2.76 2.93993C2.03111 3.66938 1.66667 4.55493 1.66667 5.5966C1.66667 6.63826 2.03111 7.52382 2.76 8.25326C3.48944 8.98215 4.375 9.3466 5.41667 9.3466Z' }))) : null, this.isDialogOpened ? [_createElement('svg', {
                    'width': '10',
                    'height': '10',
                    'viewBox': '0 0 10 10',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '12450'
                }, _createElement('path', {
                    'd': 'M0.502101 8.67349L4.0042 5.17139L0.502101 1.66929L1.66947 0.501918L5.17157 4.00402L8.67367 0.501918L9.84104 1.66929L6.33894 5.17139L9.84104 8.67349L8.67367 9.84086L5.17157 6.33875L1.66947 9.84085L0.502101 8.67349Z',
                    'fill': '#FD4A3F'
                }))] : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]