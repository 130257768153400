const baseFitmentFields = ['Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [];
const ignoreFields = ['Universal', 'Fitment'];

const desktopGarageSelector = '#cm_Garage';
const mobileGarageSelector = '#cm_MobileGarage';
const headerVehicleWidgetSelector = '#cm_HeaderVehicleWidget_garage';
const vehicleDialogButtonSelector = '#cm_VehicleWidgetDialogButton';
const vehicleDialogButtonMobileSelector = '#cm_VehicleWidgetDialogButtonMobile';
const searchBoxSelector = '#cm-search-box';

const { customerCompanyId } = globalThis.Convermax;

globalThis.Convermax.closeSearchBox = (e) => {
  window.document.querySelector(searchBoxSelector).classList.add('cm_hide');

  // make desktop garage or YMM panel normal sizes when search box is hidden
  if (!window.Convermax.getVehicleList().length) {
    window.document.querySelector(headerVehicleWidgetSelector).classList.remove('cm_hide');
    window.document.querySelector(vehicleDialogButtonSelector).removeAttribute('style');
  } else {
    window.document.querySelector(desktopGarageSelector).classList.remove('minimized');
  }

  // cancel the redirect to the search page when pressing Enter. submit comes from the SearchBox component
  if (e.type === 'keydown' && e.key === 'Enter') {
    e.stopPropagation();
  }
};

function changeElementsVisibility(selectors, isAdd) {
  selectors.forEach((s) => {
    if (isAdd) {
      window.document.querySelector(s).classList.add('cm_hide');
    } else {
      window.document.querySelector(s).classList.remove('cm_hide');
    }
  });
}
const onVehicleSelected = () => {
  // show the garage on vehicle selection
  changeElementsVisibility([desktopGarageSelector, mobileGarageSelector], false);
  changeElementsVisibility(
    [headerVehicleWidgetSelector, vehicleDialogButtonSelector, vehicleDialogButtonMobileSelector],
    true,
  );

  const statusBar = window.document.querySelector('#cm_FacetPanel_status');
  if (statusBar) {
    statusBar.innerHTML = 'The vehicle has been successfully selected.';
  }
};
const onVehicleDiscarded = () => {
  // hide garage on vehicle discard if there are no vehicles left
  if (!window.Convermax.getVehicleList().length) {
    changeElementsVisibility([desktopGarageSelector, mobileGarageSelector], true);
    changeElementsVisibility(
      [headerVehicleWidgetSelector, vehicleDialogButtonSelector, vehicleDialogButtonMobileSelector],
      false,
    );
  }

  const statusBar = window.document.querySelector('#cm_FacetPanel_status');
  if (statusBar) {
    statusBar.innerHTML = 'The vehicle has been successfully discarded.';
  }
};

window.Convermax.onFilterClick = (e) => {
  const facetentrykey = e.target.closest('.facetentrykey');
  const statusBar = window.document.querySelector('#cm_FacetPanel_status');

  if (facetentrykey && statusBar) {
    const action = e.target.closest('.cm_FilterChips') ? 'removed' : 'added';
    statusBar.innerHTML = `${facetentrykey.innerText} filter has been successfully ${action}.`;
  }
};

const AfterInit = () => {
  if (window.Convermax.getVehicleList().length) {
    // show garage if there is vehicles
    changeElementsVisibility([desktopGarageSelector, mobileGarageSelector], false);
  } else {
    // show garage if there are no vehicles
    changeElementsVisibility(
      [headerVehicleWidgetSelector, vehicleDialogButtonSelector, vehicleDialogButtonMobileSelector],
      false,
    );
  }

  // handle desktop search box
  window.document.querySelector('.search-toggle-button').addEventListener('click', () => {
    window.document.querySelector(searchBoxSelector).classList.remove('cm_hide');
    window.document.querySelector(`${searchBoxSelector} .cm_searchInput`).focus();

    // make desktop garage or YMM panel minimized when search box is shown
    if (!window.Convermax.getVehicleList().length) {
      window.document.querySelector(headerVehicleWidgetSelector).classList.add('cm_hide');
      window.document.querySelector(vehicleDialogButtonSelector).style.display = 'block';
    } else {
      window.document.querySelector(desktopGarageSelector).classList.add('minimized');
    }
  });

  // close search box if garage or YMM panel is clicked
  [desktopGarageSelector, vehicleDialogButtonSelector].forEach((s) =>
    window.document.querySelector(s).addEventListener('click', (e) => window.Convermax.closeSearchBox(e)),
  );

  // { KF-1003|SBAF49-R: "https://chats.landbot.io/v3/H-1192102-0LVQ8P08502RU5ME/index.html" }
  globalThis.Convermax.parsedLandbotSkus = JSON.parse(window.theme.settings.landbot_skus);
  // ['KF-1003|SBAF49-R', 'KF-1035|KF-1032|KF-1000'];
  globalThis.Convermax.landbotSkus = Object.keys(globalThis.Convermax.parsedLandbotSkus);
};

const updateCallback = () => {
  const skuWithoutLandbot = [...window.document.querySelectorAll('.cm_SearchResult .splide__slide')]
    .filter((el) => !el.querySelector('.landbotBTN'))
    .map((el) => el.getAttribute('data-product-sku'));

  if (!skuWithoutLandbot.length) {
    return;
  }

  window.Convermax.landbotSkus.forEach((key) => {
    // 'KF-1035|KF-1032|KF-1000' => ['KF-1035', 'KF-1032', 'KF-1000']
    const skus = key.split('|');

    if (skus.every((sku) => skuWithoutLandbot.includes(sku))) {
      skus.forEach((sku) => {
        const landbotButton = document.createElement('div');
        landbotButton.setAttribute('data-hook', 'open-landbot');
        landbotButton.classList.add('landbotBTN');
        landbotButton.innerHTML = `
          <div class="bg--red color--white font--uppercase" tabindex="0" role="button">
            Confirm This Is Your Filter
          </div>`;
        const landbotButtonClicked = () => {
          new window.LandbotFrameWidget({
            index: window.Convermax.parsedLandbotSkus[key],
            container: '#landbot-container',
          });
        };
        landbotButton.addEventListener('click', landbotButtonClicked);
        landbotButton.addEventListener('keydown', function (e) {
          if (e.type === 'keydown' && e.key === 'Enter') {
            landbotButtonClicked();
          }
        });

        const product = window.document.querySelector(
          `.cm_SearchResult .splide__slide[data-product-sku="${sku}"]`,
        );

        product.insertBefore(landbotButton, product.querySelector('.cut-corner'));
      });
    }
  });
};

export default {
  AfterInit,
  platform: 'shopify',
  SearchRequestDefaults: {
    pageSize: 18,
    extra: customerCompanyId ? { customerCompanyId } : {},
  },
  autocomplete: {
    requestDefaults: { extra: customerCompanyId ? { customerCompanyId } : {} },
  },
  product: {
    dropdownImageWidth: 60,
    dropdownImageHeight: 60,
    searchResultsImageWidth: 380,
    searchResultsImageHeight: 380,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isAutoVehicleSelectionDisabled: true,
    isVehicleSelectionIsolated: true,
    facetDialogInitCollapsed: false,
    onVehicleSelected,
    onVehicleDiscarded,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'QueryLabel',
      type: 'FacetPanel',
      location: '#search-results.template-page .rich-text .richtext__text-block',
      template: 'QueryLabel',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchResult',
      updateCallback,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      location: { selector: searchBoxSelector, class: 'cm-search-box' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        selector: '#cm-mobile-search-box',
        class: 'cm-mobile-search-box',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'Garage',
      location: { replace: '#cm-garage', class: 'cm_garage__desktop cm_hide' },
      template: 'fitmentSearch/garage',
      useVehicleWidget: true,
      isAutoVehicleSelectionDisabled: false,
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { replace: '#cm-mobile-garage', class: 'cm_garage__mobile hide-desktop-up cm_hide' },
      template: 'fitmentSearch/garage',
      useVehicleWidget: true,
      isAutoVehicleSelectionDisabled: false,
    },
    {
      name: 'LabelVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm-label',
      template: 'fitmentSearch/labelVehicleWidget',
    },
    {
      // show only when "active" (only dropdowns) and make it always row
      name: 'HeaderVehicleWidget_garage',
      type: 'VehicleWidget',
      location: {
        replace: '#cm-vehicle-widget-garage',
        class: 'cm_vehicle-widget__garage-header-container cm_hide',
      },
      template: 'fitmentSearch/garageVehicleWidget',
      isAlwaysActive: false,
      columnBreakpoint: 1,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysColumnLayout: true,
      useNativeDropdown: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
      isAutoVehicleSelectionDisabled: false,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialogButton',
      type: 'DialogButton',
      location: {
        replace: '#cm-vehicle-widget-garage-dialog-button',
        class: 'cm_vehicle-widget__dialog-button-container cm_hide',
      },
      template: 'fitmentSearch/vehicleWidgetDialogButton',
      dialogName: 'vehicle-dialog',
    },
    {
      name: 'VehicleWidgetDialogButtonMobile',
      type: 'DialogButton',
      location: {
        replace: '#cm-vehicle-widget-garage-dialog-button-mobile',
        class: 'cm_vehicle-widget__dialog-button-container hide-desktop-up cm_hide',
      },
      template: 'fitmentSearch/vehicleWidgetDialogButton',
      dialogName: 'vehicle-dialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'CustomFacetDialog',
      type: 'ContextDialog',
      location: { class: 'FacetDialog' },
      template: 'CustomFacetDialog',
    },
    {
      name: 'CustomFacetDialogButton',
      type: 'DialogButton',
      dialogName: 'CustomFacetDialog',
    },
    {
      name: 'CustomFacetDialogFacetPanel',
      type: 'FacetPanel',
    },
    {
      name: 'CustomFacetDialogVehicleWidget',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      useNativeDropdown: true,
    },
  ],
};
