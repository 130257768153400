
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxDialogRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog' }, _createElement('div', { 'className': 'clear-self cm_search-box_container' }, _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'dialog-content',
        'onSubmit': this.preventFormSubmission
    }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Search'), _createElement('div', {
        'className': 'cm_search-box-dialog_close-button close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'image',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' })))), _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'cm_searchInput' }, window.Convermax.config?.searchBoxPlaceholderText || 'Search');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('button', {
            'onClick': e => window.Convermax.closeSearchBox(e),
            'onKeyDown': e => window.Convermax.closeSearchBox(e),
            'className': 'cm_search-box_close',
            'key': '5832'
        }, [_createElement('svg', {
                'width': '10',
                'height': '10',
                'viewBox': '0 0 10 10',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'aria-hidden': 'true',
                'key': '10330'
            }, _createElement('path', {
                'd': 'M0.502101 8.67349L4.0042 5.17139L0.502101 1.66929L1.66947 0.501918L5.17157 4.00402L8.67367 0.501918L9.84104 1.66929L6.33894 5.17139L9.84104 8.67349L8.67367 9.84086L5.17157 6.33875L1.66947 9.84085L0.502101 8.67349Z',
                'fill': '#FD4A3F'
            }))])
    ]), [this.dropdown(function () {
            function repeatSections1(sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', { 'className': 'cmTemplate_text' }) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                        'src': this.imageOrDefault(this.resizeImage(this.image || this.image1)),
                                        'alt': 'Image of ' + this.removeHTML(this.sku),
                                        'onError': this.onImageError
                                    })), _createElement('div', { 'className': 'description-block' }, _createElement('h2', mergeProps_product({ 'className': 'h7 m0 p0' }, { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('span', { 'className': 'b-border' }), _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })))) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return this.sectionTitle === 'Products' ? _createElement('div', {
                            'className': 'section',
                            'key': '3'
                        }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_items' },
                            _map(this.items, repeatItems1.bind(this))
                        ])) : null;
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'dialog-body cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_sections' },
                _map(this.sections, repeatSections1.bind(this))
            ]), [this.browseAllButton(function () {
                    return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, '\n  SEARCH \u201C', this.query, '\u201D\n  ', [_createElement('svg', {
                            'width': '15',
                            'height': '15',
                            'viewBox': '0 0 15 15',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'aria-hidden': 'true',
                            'key': '880'
                        }, _createElement('path', {
                            'fillRule': 'evenodd',
                            'clipRule': 'evenodd',
                            'd': 'M2.17871 11.3206L6.35283 7.5639C6.46317 7.46459 6.46317 7.29156 6.35283 7.19225L2.17871 3.43555L3.18216 2.32061L7.35627 6.07731C8.12869 6.77248 8.12869 7.98367 7.35628 8.67884L3.18216 12.4355L2.17871 11.3206Z',
                            'fill': '#D92125'
                        }), _createElement('path', {
                            'fillRule': 'evenodd',
                            'clipRule': 'evenodd',
                            'd': 'M8 11.3206L12.1741 7.5639C12.2845 7.46459 12.2845 7.29156 12.1741 7.19225L8 3.43555L9.00345 2.32061L13.1776 6.07731C13.95 6.77248 13.95 7.98367 13.1776 8.67884L9.00345 12.4355L8 11.3206Z',
                            'fill': '#D92125'
                        }))]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]