//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-520:_3340,_7640,_5496,_6928,_3984,_8000,_9712,_6332;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-520')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-520', "_3340,_7640,_5496,_6928,_3984,_8000,_9712,_6332");
        }
      }catch (ex) {
        console.error(ex);
      }